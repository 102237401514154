import { CssBaseline, GeistProvider, Themes } from "@geist-ui/core";
import type { LinksFunction } from "@remix-run/cloudflare";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useNavigate
} from "@remix-run/react";
import { useEffect } from 'react';
import { Provider, useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState, setUser, store } from "./appSlice";
import { getCurrentUserId } from "./auth.client";
import { initializeFirebaseApp } from "./firebase";

export const wind: any = typeof window !== 'undefined'
	? window
	: {};

const originalError = console.error;
console.error = (...args) => {
	if (!/Support for defaultProps/.test(args[0]) && !/Unknown event handler/.test(args[0]) && !/Unsupported style property/.test(args[0])) {
		originalError(...args);
	}
};;

export const links: LinksFunction = () => [
	{ rel: "preconnect", href: "https://fonts.googleapis.com" },
	{
		rel: "preconnect",
		href: "https://fonts.gstatic.com",
		crossOrigin: "anonymous",
	},
	{
		rel: "stylesheet",
		href: "https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap",
	},
	...(!wind?.env?.electron ? [] : [
		{ rel: "stylesheet", href: "/mathquill.css" },
		{ rel: "stylesheet", href: "/tailwind.css" },
		{ rel: "stylesheet", href: "/katex.css" },
	]),
];

const providePage = (isGuest: boolean, navigate: any, dispatch: any) => {
	getCurrentUserId().then((uid) => {
		if (isGuest) {
			navigate('/app');
		} else if (uid) {
			dispatch(setUser(uid));
			navigate('/app');
		} else {
			navigate('/login');
		}
	});
}

export function App() {
	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();
	const { isDarkTheme, uid, guest } = useSelector((state: RootState) => state.app);
	const lightTheme = Themes.createFromLight({ type: 'light1' });
	const darkTheme = Themes.createFromDark({ type: 'dark1', palette: { background: "#151515" } });

	useEffect(() => {
		if (!wind?.env?.electron) {
			import("./mathquill.css").catch(console.error);
			import("./tailwind.css").catch(console.error);
			import("./katex.css").catch(console.error);
		}

		initializeFirebaseApp();
		providePage(guest, navigate, dispatch)
	}, [navigate, uid]);

	return (
		<GeistProvider themes={[lightTheme, darkTheme]} themeType={isDarkTheme ? 'dark1' : 'light1'}>
			<CssBaseline />
			<Outlet />
		</GeistProvider>
	);
}

export default function Root() {
	return (
		<html lang="en">
			<head>
				<Meta />
				<Links />
			</head>
			<body>
				<Provider store={store}>
					<App />
					<ScrollRestoration />
					<Scripts />
				</Provider>
			</body>
		</html>
	);
}