import { wind } from "./root";

export async function initializeFirebaseApp() {
    if (wind !== undefined && !wind.firebase) {
        try {
            const firebaseConfig = {
                // @ts-ignore
                apiKey: import.meta.env?.VITE_FIREBASE_API_KEY ?? import.meta.env?.FIREBASE_API_KEY ?? wind.env.FIREBASE_API_KEY,
                // @ts-ignore
                authDomain: import.meta.env?.VITE_FIREBASE_AUTH_DOMAIN ?? import.meta.env?.FIREBASE_AUTH_DOMAIN ?? wind.env.FIREBASE_AUTH_DOMAIN,
                // @ts-ignore
                projectId: import.meta.env?.VITE_FIREBASE_PROJECT_ID ?? import.meta.env?.FIREBASE_PROJECT_ID ?? wind.env.FIREBASE_PROJECT_ID,
                // @ts-ignore
                storageBucket: import.meta.env?.VITE_FIREBASE_STORAGE_BUCKET ?? import.meta.env?.FIREBASE_STORAGE_BUCKET ?? wind.env.FIREBASE_STORAGE_BUCKET,
                // @ts-ignore
                messagingSenderId: import.meta.env?.VITE_FIREBASE_MESSAGING_SENDER_ID ?? import.meta.env?.FIREBASE_MESSAGING_SENDER_ID ?? wind.env.FIREBASE_MESSAGING_SENDER_ID,
                // @ts-ignore
                appId: import.meta.env?.VITE_FIREBASE_APP_ID ?? import.meta.env?.FIREBASE_APP_ID ?? wind.env.FIREBASE_APP_ID,
                // @ts-ignore
                measurementId: import.meta.env?.VITE_FIREBASE_MEASUREMENT_ID ?? import.meta.env?.FIREBASE_MEASUREMENT_ID ?? wind.env.FIREBASE_MEASUREMENT_ID,
            };

            // @ts-ignore
            wind.firebase = await import("https://www.gstatic.com/firebasejs/10.13.2/firebase-app.js");
            // @ts-ignore
            wind.firebaseAuthModule = await import("https://www.gstatic.com/firebasejs/10.13.2/firebase-auth.js");
            // @ts-ignore
            wind.firebaseFirestoreModule = await import("https://www.gstatic.com/firebasejs/10.13.2/firebase-firestore.js");
            wind.firebaseApp = wind.firebase.initializeApp(firebaseConfig);
            wind.firebaseAuth = wind.firebaseAuthModule.getAuth();
            wind.firebaseFirestore = wind.firebaseFirestoreModule.initializeFirestore(wind.firebaseApp, {
                localCache: wind.firebaseFirestoreModule.persistentLocalCache(),
            });
        } catch (error) {
            console.error('Failed to initialize app:', error);
        }
    }
}