import { setUser } from './appSlice';
import { wind } from './root';
import { store } from './appSlice';

export function getCurrentUserId(): Promise<string | null> {
  return new Promise<string | null>((resolve) => {
    const checkFirebaseAuth = () => {
      if (wind.firebaseAuth) {
        const unsubscribe = wind.firebaseAuth.onAuthStateChanged((user: { uid: string | PromiseLike<string | null> | null }) => {
          if (user) {
            resolve(user.uid);
          } else {
            resolve(null);
          }
          unsubscribe();
        });
      } else {
        setTimeout(checkFirebaseAuth, 10);
      }
    };

    checkFirebaseAuth();
  });
}

export async function login(email: string, password: string) {
  const userCredential = await wind.firebaseAuthModule.signInWithEmailAndPassword(wind.firebaseAuth, email, password);
  store.dispatch(setUser(userCredential.user.uid));
  return userCredential.user;
}

export async function signup(email: string, password: string) {
  const userCredential = await wind.firebaseAuthModule.createUserWithEmailAndPassword(wind.firebaseAuth,email, password);
  store.dispatch(setUser(userCredential.user.uid));
  return userCredential.user;
}

export async function sendResetPasswordEmail(email: string) {
  await wind.firebaseAuthModule.sendPasswordResetEmail(email);
}

export async function resetPassword(oobCode: string, newPassword: string) {
  await wind.firebaseAuthModule.confirmPasswordReset(oobCode, newPassword);
}

export async function signOutUser() {
  await wind.firebaseAuthModule.signOut(wind.firebaseAuth);
  store.dispatch(setUser(null));
}